import { createApp } from 'vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import App from './App.vue'
import router from './router'
import store from './store'
import './css/helper-classes.scss'
import './css/typography.scss'
import './css/reset.scss'

const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(VueSmoothScroll)
  .mount('#app')

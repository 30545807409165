<template>
  <div class="toggle-list">
    <ul>
      <ToggleItem
          v-for="(item, index) in items"
          :key="index"
          :isOpen="openIndex === index"
          :onToggle="() => toggle(index)"
      >
        <template #header>
          <slot name="header" :item="item" :index="index"></slot>
        </template>
        <template #content>
          <slot name="content" :item="item" :index="index"></slot>
        </template>
      </ToggleItem>
    </ul>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import ToggleItem from './ToggleItem.vue';

  const props = defineProps({
    items: {
      type: Array,
      required: true
    }
  });

  const openIndex = ref(0);

  function toggle(index) {
    if (openIndex.value === index) {
      openIndex.value = null;
    } else {
      openIndex.value = index;
    }
  }
</script>

<style lang="scss">
</style>
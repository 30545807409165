import { createStore } from 'vuex'
import * as contact from './modules/contact'
import { content } from './data.js'
import router from '../router'

export default createStore({
  state: {
    content,
    activeLanguage: 'ro',
  },
  getters: {
    content: state => state.content[state.activeLanguage],
  },
  mutations: {
    SET_LANGUAGE(state, language) {
      state.activeLanguage = language;
    },
  },
  actions: {
    changeLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language);
      router.currentRoute.value.name.includes('regulament') && router.push(`/regulament-${language}`)
    },
  },
  modules: {
    contact,
  }
})

<template>
  <div class="toggle-item">
    <li @click="toggle">
      <div class="item-header">
        <slot name="header"></slot>
      </div>
      <transition name="toggle">
        <div v-show="isOpen" class="item-content">
          <slot name="content"></slot>
        </div>
      </transition>
    </li>
  </div>
</template>

<script setup>
  const props = defineProps({
    isOpen: {
      type: Boolean,
      required: true
    },
    onToggle: {
      type: Function,
      required: true
    }
  });

  function toggle() {
    props.onToggle();
  }
</script>

<style lang="scss">
  .toggle-item {
    .item-header {
      cursor: pointer;
    }
    .toggle-enter-active, .toggle-leave-active {
      transition: all 0.3s ease;
    }

    .toggle-enter-from, .toggle-leave-to {
      max-height: 0;
      opacity: 0;
    }
    .toggle-enter-to, .toggle-leave-from {
      max-height: 500px;
      opacity: 1;
    }
    .item-content {
      overflow: hidden;
    }
  }
</style>


import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegulamentRoView from "@/views/RegulamentRoView.vue";
import RegulamentEnView from "@/views/RegulamentEnView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/regulament-ro',
    name: 'regulament-ro',
    component: RegulamentRoView
  },
  {
    path: '/regulament-en',
    name: 'regulament-en',
    component: RegulamentEnView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.name.includes('regulament')) {
      return { top: 0 }
    }
  }
})

export default router

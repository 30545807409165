import axios from "axios";

export const state = {
  form: {
    name: '',
    from: '',
    message: ''
  }
}

export const mutations = {
  SET_FORM_FIELD (state, {name, value}) {
    state.form[name] = value
  },
  RESET_FORM(state) {
    state.form = { name: '', from: '', message: '' };
  },
}

export const actions = {
  async submitContactForm({ commit }, formData) {
    try {
      const {data} = await axios.post('https://muralsofyouth.ro/api/contact', formData);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
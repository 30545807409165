<template>
  <main class="regulament-view">
    <div>
      <h1>Regulament de Participare la Evenimentul M.Y. - Murals of Youth</h1>

      <div class="section">
        <p>M.Y. - MURALS OF YOUTH este o competiție internațională în domeniul artei murale care se desfățoară în perioada 30 august - 01 septembrie 2024, în Piața Unirii din Cluj-Napoca, si care celebrează creativitatea și talentul elevilor de liceu.</p>

        <p>Murals of Youth își propune să promoveze educația prin artă, oferind elevilor de liceu și publicului larg o platformă pentru a explora arta și abilitățile artistice.</p>

        <p>Competiția de artă murală, un moment de vârf al festivalului, nu doar că încurajează o competiție sănătoasă, dar oferă și participanților oportunitatea de a învăța prin practică și de a-și expune valentele si talentul artistic.</p>

        <p>Pe lângă competiție, M.Y. organizează ateliere de artă speciale pentru vizitatorii festivalului. Festivalul încurajează de asemenea un mediu propice pentru discuții deschise între elevi și profesioniști, permițându-le să împărtășească experiențe și să primească sfaturi practice prin organizarea de conferințe tematice.</p>
      </div>

      <div class="section">
        <h2>1. REGULAMENT</h2>
        <p>Prezentul regulament se aplică pentru evenimentele organizate de Asociația Comunitatea 156, denumit în continuare Organizator și definește regulile pe care fiecare participant la eveniment trebuie să le respecte, precum și drepturile și obligațiile Organizatorului și ale Participantului. Respectarea acestui regulament este obligatorie pentru toate persoanele prezente la activitățile desfășurate în cadrul festivalului-concurs M.Y. - Murals of Youth. În caz de nerespectare, Organizatorul își rezervă dreptul de a refuza intrarea sau de a exclude persoanele în cauză din perimetrul evenimentului. Organizatorul își rezervă dreptul de a modifica acest regulament când se impune, orice modificare fiind afișată pe Instagram și pe pagina evenimentului la M.Y. - Murals of Youth - (<a href="https://muralsofyouth.ro/#about-us">https://muralsofyouth.ro/#about-us</a>). Participantul se obligă prin participarea în festival, să respecte acest regulament.</p>
      </div>

      <div class="section">
        <h2>2. ACCES, PARTICIPARE</h2>
        <h3>2.1 Acces</h3>
        <p>Accesul la eveniment este liber. Accesul minorilor (0-18 ani) se face doar însoțiți de un adult (părinte sau tutore legal) și supravegheați permanent de acesta/aceștia.</p>

        <h3>2.2 Participarea</h3>
        <p>Participarea adulților (părinților) și a minorilor însoțiți de aceștia se face pe răspunderea adulților care îi însoțesc, inclusiv cu privire la:</p>
        <ol type="a">
          <li>Persoanele cu boli psihice sau fizice pentru care zonele aglomerate, zgomotele puternice, efectele speciale sonore, vizuale și audio, reprezintă risc; adulții ce îi însoțesc își asumă întreaga răspundere pentru prejudiciile pe care le-ar putea suferi.</li>
          <li>În zonele de ateliere, distracție și suport și alte zone cu risc de accidentare, adulții (părinți, bunici, alte rude sau tutori) vor asigura că minorii pe care îi însoțesc dețin condiția fizică și psihică pentru participare. Spectacolele pot include puternice efecte de lumină care ar putea dăuna copiilor sau persoanelor epileptice. Organizatorul nu își asumă răspunderea pentru riscurile de vătămare sau prejudiciile materiale.</li>
        </ol>
      </div>

      <div class="section">
        <h2>3. ESTE INTERZIS:</h2>
        <ul>
          <li>Accesul cu arme sau obiecte periculoase</li>
          <li>Accesul cu recipiente de sticlă, materiale inflamabile, pixuri cu laser</li>
          <li>Comercializarea de produse fără acordul organizatorului</li>
          <li>Consumul sau comercializarea de substanțe interzise</li>
          <li>Accesul cu alcool</li>
          <li>Accesul cu animale</li>
          <li>Desenarea în afara zonelor special amenajate</li>
        </ul>
      </div>

      <div class="section">
        <h2>4. VIDEO / FOTO</h2>
        <p>Este interzis accesul cu camere video profesionale și semi-profesionale, inclusiv camere foto cu obiectiv detașabil fără autorizația organizatorului. Înregistrările audio și video ale evenimentului pot include imagini și voci ale participanților. Organizatorul, partenerii sau presa acreditată au dreptul de a înregistra și fotografia evenimentul. Filmarea și fotografierea spectacolelor pentru difuzare în presă necesită acordul departamentului de comunicare și marketing al Organizatorului.</p>
      </div>

      <div class="section">
        <h2>5. REGULI DE CONDUITĂ</h2>
        <p>Participanții trebuie să respecte regulile sociale și legile în vigoare. Este interzisă desfășurarea de activități care pot pune în pericol siguranța sau drepturile altor participanți. Participanții trebuie să respecte și să nu distrugă mediul înconjurător. Consumul de droguri este ilegal și strict interzis.</p>
      </div>

      <div class="section">
        <h2>6. SANCȚIUNI</h2>
        <p>Organizatorul are dreptul de a interzice accesul sau de a invita un participant să părăsească perimetrul evenimentului în caz de încălcare a regulamentului sau a legilor în vigoare.</p>
      </div>

      <div class="section">
        <h2>7. CAZ FORȚĂ MAJORĂ</h2>
        <p>În caz de război, revoltă, atac terorist, dezastru natural sau alte cazuri de forță majoră, Organizatorul nu este răspunzător pentru prejudiciile suferite de participanți.</p>
      </div>

      <div class="section">
        <h2>8. PRELUCRAREA DATELOR CU CARACTER PERSONAL</h2>
        <p>Organizatorul prelucrează datele cu caracter personal ale participanților și însoțitorilor pentru facilitarea accesului la eveniment și mediatizarea acestuia. Datele sunt prelucrate conform Regulamentului European nr. 679/2016 și legislației naționale aplicabile. Participanții au dreptul de acces, rectificare sau ștergere a datelor și alte drepturi conform legislației în vigoare. Cererile pot fi adresate pe adresa comunitatea156@gmail.com.</p>
      </div>

      <div class="section">
        <h2>9. INFORMAȚII GENERALE</h2>
        <p>Pentru protecția copiilor, se recomandă utilizarea căștilor pentru protecție fonică și păstrarea unei distanțe rezonabile față de echipamentele de sonorizare. Organizatorul nu este responsabil pentru bunurile participanților ori pentru eventualele pagube personale produse. Datele și orele de desfășurare pot fi modificate de către Organizator. Acesta este exonerat de responsabilitățile ce nu sunt cauzate de organizare defectuoasă sau neglijență.</p>
        <p>Organizatorul își rezervă dreptul de a efectua controale pentru a se asigura de respectarea regulamentului. Încălcarea regulamentului poate duce la evacuare și interzicerea accesului la spectacolele viitoare.</p>
        <p>Vă mulțumim pentru înțelegere și vă dorim distracție plăcută!</p>
      </div>
    </div>
  </main>
</template>

<script setup>
  
</script>

<style lang="scss">
  .regulament-view {
    @media only screen and (min-width: 0) {
      padding: 100px 40px 60px;
      > div {
        max-width: 800px;
        margin: 0 auto;
      }
      h1 {
        color: #333;
        text-align: center;
        font-size: 3rem;
        font-weight: 500;
      }
      h2 {
        color: #444;
        margin-top: 30px;
      }
      p {
        margin-bottom: 15px;
      }
      ul {
        margin-bottom: 15px;
      }
    }
  }
</style>
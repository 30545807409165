<template>
  <main class="regulament-view">
    <div>
      <h1>Participation Rules for M.Y. - Murals of Youth Event</h1>

      <p>M.Y. - MURALS OF YOUTH is an international mural art competition taking place from August 30 to September 1, 2024, in Unirii Square, Cluj-Napoca, celebrating the creativity and talent of high school students.</p>

      <p>Murals of Youth aims to promote education through art, offering high school students and the general public a platform to explore art and artistic skills.</p>

      <p>The mural art competition, a highlight of the festival, not only encourages healthy competition but also offers participants the opportunity to learn through practice and showcase their artistic talents.</p>

      <p>In addition to the competition, M.Y. organizes special art workshops for festival visitors. The festival also promotes an environment conducive to open discussions between students and professionals, allowing them to share experiences and receive practical advice through themed conferences.</p>

      <h2>1. RULES</h2>
      <p>These rules apply to events organized by the 156 Community Association, hereinafter referred to as the Organizer, and define the rules that each event participant must follow, as well as the rights and obligations of the Organizer and the Participant. Compliance with these rules is mandatory for all persons present at the activities carried out within the M.Y. - Murals of Youth festival-competition. In case of non-compliance, the Organizer reserves the right to refuse entry or exclude the persons concerned from the event perimeter. The Organizer reserves the right to modify these rules when necessary, with any changes being posted on Instagram and on the event page at M.Y. - Murals of Youth - (<a href="https://muralsofyouth.ro/#about-us">https://muralsofyouth.ro/#about-us</a>). The Participant agrees to comply with these rules by participating in the festival.</p>

      <h2>2. ACCESS, PARTICIPATION</h2>
      <h3>2.1 Access</h3>
      <p>Access to the event is free. Access for minors (0-18 years) is only allowed when accompanied by an adult (parent or legal guardian) and under their constant supervision.</p>

      <h3>2.2 Participation</h3>
      <p>The participation of adults (parents) and minors accompanied by them is at the responsibility of the accompanying adults, including with regard to:</p>
      <ol type="a">
        <li>Persons with mental or physical illnesses for whom crowded areas, loud noises, special sound, visual and audio effects pose a risk; accompanying adults assume full responsibility for any harm they may suffer.</li>
        <li>In workshop areas, entertainment and support areas, and other areas with risk of injury, adults (parents, grandparents, other relatives or guardians) will ensure that the minors they accompany have the physical and mental condition to participate. Shows may include strong light effects that could harm children or epileptic persons. The Organizer does not assume responsibility for risks of injury or material damages.</li>
      </ol>

      <h2>3. IT IS FORBIDDEN:</h2>
      <ul>
        <li>Access with weapons or dangerous objects</li>
        <li>Access with glass containers, flammable materials, laser pens</li>
        <li>Selling products without the organizer's agreement</li>
        <li>Consumption or sale of prohibited substances</li>
        <li>Access with alcohol</li>
        <li>Access with animals</li>
        <li>Drawing outside specially designated areas</li>
      </ul>

      <h2>4. VIDEO / PHOTO</h2>
      <p>Access with professional and semi-professional video cameras, including photo cameras with detachable lenses, is prohibited without the organizer's authorization. Audio and video recordings of the event may include images and voices of participants. The Organizer, partners, or accredited press have the right to record and photograph the event. Filming and photographing performances for press distribution requires the agreement of the Organizer's communication and marketing department.</p>

      <h2>5. CODE OF CONDUCT</h2>
      <p>Participants must respect social rules and laws in force. It is forbidden to carry out activities that may endanger the safety or rights of other participants. Participants must respect and not destroy the surrounding environment. Drug use is illegal and strictly forbidden.</p>

      <h2>6. SANCTIONS</h2>
      <p>The Organizer has the right to prohibit access or invite a participant to leave the event perimeter in case of violation of the rules or laws in force.</p>

      <h2>7. FORCE MAJEURE</h2>
      <p>In case of war, revolt, terrorist attack, natural disaster, or other cases of force majeure, the Organizer is not responsible for damages suffered by participants.</p>

      <h2>8. PROCESSING OF PERSONAL DATA</h2>
      <p>The Organizer processes the personal data of participants and accompanying persons to facilitate access to the event and its media coverage. Data is processed in accordance with European Regulation no. 679/2016 and applicable national legislation. Participants have the right to access, rectify or delete data and other rights under current legislation. Requests can be addressed to <a href="mailto:comunitatea156@gmail.com">comunitatea156@gmail.com</a>.</p>

      <h2>9. GENERAL INFORMATION</h2>
      <p>For child protection, it is recommended to use headphones for hearing protection and maintain a reasonable distance from sound equipment. The Organizer is not responsible for participants' belongings or for any personal damages. Dates and times of events may be modified by the Organizer. The Organizer is exempt from responsibilities not caused by faulty organization or negligence.</p>

      <p>The Organizer reserves the right to conduct checks to ensure compliance with the rules. Violation of the rules may lead to evacuation and prohibition of access to future shows.</p>

      <p>Thank you for your understanding and we wish you a pleasant entertainment!</p>
    </div>
  </main>
</template>

<script setup>
  
</script>

<style lang="scss">
  .regulament-view {
    @media only screen and (min-width: 0) {

    }
  }
</style>
<template>
  <footer class="main-footer text-center">
    <figure class="m-b-16">
      <img src="../assets/logo-white.svg" alt="murals of youth" />
    </figure>

    <ul class="flex flex-wrap flex-center mauto">
      <li v-for="({id, name, line}, index) in c.navigationFooter.pages"
          :key="index"
          @click="scrollToMyEl(id)">
        <span>{{ name }}</span>
        <span v-if="line">&nbsp;|&nbsp;</span>
      </li>
    </ul>

    <div class="s-m m-t-16 flex flex-center">
      <a href="https://instagram.com">
        <img src="../assets/insta.svg" alt="murals of youth instagram" />
      </a>
      <a href="https://facebook.com">
        <img src="../assets/fb.svg" alt="murals of youth facebook" />
      </a>
    </div>
  </footer>
</template>

<script setup>
import { computed, inject } from "vue";
  import { useStore } from "vuex";
  const { getters } = useStore();

  const c = computed(() => getters.content)

  const smoothScroll = inject('smoothScroll')

  function scrollToMyEl (id) {
    const elm = document.getElementById(id);
    smoothScroll({
      scrollTo: elm,
      offset: -60
    })
  }
</script>

<style lang="scss">
  @import "../css/vars";

  .main-footer {
    @media only screen and (min-width: 0) {
      padding: 40px 0 60px;
      background-color: $dark-green;
      ul {
        max-width: 250px;
        li {
          color: white;
          cursor: pointer;
          span {
            font-weight: 200;
          }
        }
      }

      .s-m {
        a:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
</style>
export const content = {
  en: {
    navigationHeader: {
      pages: [
        { id: 'about-us', title: 'About us' },
        { id: 'schedule', title: 'Schedule' },
        { id: 'gallery', title: 'Gallery' },
        // { id: 'sponsors', title: 'Sponsors' },
        { id: 'faq', title: 'Faq' },
        { id: 'rules', title: 'Rules' },
        { id: 'contact', title: 'Contact' },
      ]
    },
    navigationFooter: {
      pages: [
        { name: 'about', id: 'about-us', line: true },
        { name: 'schedule', id: 'schedule', line: true },
        { name: 'gallery', id: 'gallery', line: true },
        { name: 'faq', id: 'faq', line: true },
        { name: 'Rules', id: 'rules', line: true },
        // { name: 'sponsors', id: 'sponsors' },
        { name: 'contact', id: 'contact' },
      ]
    },
    mainTitle: `Welcome to Murals of Youth`,
    subTitle: `transform your imagination into art`,
    welcomeSection: {
      description: `Welcome to <b>Murals of Youth</b>, an international festival celebrating the creativity of high school students in mural art. Everything started in 2023 in the vibrant city of
        Cluj-Napoca, Romania, the festival features artists offers participants the opportunity to showcase their skills on
        large canvases panels using various painting techniques.
        To participate, high school students can submit their participation form online until 15th of July. A jury of four, including artists and teachers, will select participants and
        the winners and we also offer prizes.`,
      unleashCreativity: `Unleash your creativity at the
        Murals of Youth Festival in <br> Cluj-Napoca, <br>
        <b>30 August – 1 September 2024!</b> <br>
        <span>Registration open until 15 July.</span>`,
      rulesOfParticipationTitle: "Rules",
      rulesOfParticipation: "Participation Rules",
      breakdanceRules: "Breakdance contest rules",
    },
    artworksSection: {
      title: `M.Y. 2023`,
      hashtag: `#2023contestartwork`,
    },
    aboutSection: {
      introduction:  `<b>MURALS OF YOUTH</b>
        is an international competition celebrating the creativity and talent of high school students in the field of mural art.`,
      details: `Murals of Youth aims to promote education through art by providing high school students and the wider public with a platform to explore art and artistic skills.
        <br><br>
        The mural art competition, a highlight of the festival, not only encourages healthy competition but also offers participants the opportunity to learn through practice and showcase their talents to a large audience.
        <br><br>
        In addition to the competition, M.Y. hosts special art workshops for festival visitors. The festival also fosters an environment conducive to open discussions between students and professionals, allowing them to share experiences and receive practical advice.`,
    },
    scheduleSection: {
      description: `The full schedule will be announced on August 25th! Check back to find out all the planned activities and events.
        Please note that the schedule might change, so we encourage you to check for updates regularly to stay informed about the latest news.`,
      days: [
        {
          date: 'friday / 30.08',
          events: [
            {
              time: '10:00 - 20:00',
              title: 'Mural Art Contest',
              text: ``,
            },
            {
              time: '10:00 - 22:00',
              title: 'Sidewalk Art',
            },
            {
              time: '10:00 - 22:00',
              title: 'Bus Art',
            },
            {
              time: '14:00 - 15:00',
              title: 'Opening Conference',
            },
            {
              time: '17:00 - 20:00',
              title: 'Workshops',
            }
          ]
        },
        {
          date: 'saturday / 31.08',
          events: [
            {
              time: '10:00 - 20:00',
              title: 'Mural Art Contest',
              text: ``,
            },
            {
              time: '10:00 - 22:00',
              title: 'Bus Art',
            },
            {
              time: '10:00 - 22:00',
              title: 'Sidewalk Art',
            },
            {
              time: '13:00 - 15:00',
              title: 'Photography Conference',
            },
            {
              time: '15:00 - 17:00',
              title: 'Workshops',
            },
            {
              time: '17:00 - 20:00',
              title: 'Breakdance Contest',
            }
          ]
        },
        {
          date: 'sunday / 01.09',
          events: [
            {
              time: '10:00 - 17:00',
              title: 'Mural Art Contest',
              text: ``,
            },
            {
              time: '10:00 - 17:00',
              title: 'Sidewalk Art',
            },
            {
              time: '14:00 - 17:00',
              title: 'Workshops',
            },
            {
              time: '17:00 - 18:00',
              title: 'Judging',
            },
            {
              time: '19:00',
              title: 'Awards Ceremony',
            },
            {
              time: '20:00',
              title: 'Ada Morar Concert',
            },
          ]
        }
      ]
    },
    gallerySection: {
      description: `Comunitatea 156, initiated by Prof. Dr. Sergiu Georgian Mazerschi in 2021, is a group focused on extracurricular projects that highlight and nurture the creativity and talent of students at the "Romulus Ladea" Visual Arts High School in Cluj Napoca. On their website, you can discover more mural artworks, including those from Murals of Youth.
        <br><br>
        For more details and to explore their artworks, visit the Comunitatea 156 page.`,
      viewMurals: `view C156 murals`,
    },
    sponsorsSection: {},
    faqSection: {
      faq: [
        {
          q: `What is Murals of Youth?`,
          a: `Murals of Youth is an international festival celebrating creativity and street art, it consists in creating mural art in the center of Cluj-Napoca and it is dedicated to high school students.`
        },
        {
          q: `How can I register?`,
          a: `Registration involves submitting sketches, portfolios, and registration details online from March 18 to July 15, 2024.`
        },
        {
          q: `Where is the festival located?`,
          a: `The festival takes place in Unirii Square (the center of Cluj), Cluj-Napoca, Romania.`
        },
        {
          q: `What should I bring?`,
          a: `Participants should bring their artistic materials and personal items. Specific guidelines will be provided upon registration, but we mostly offer all the resources needed.`
        },
        {
          q: `What are the eligibility criteria for high school students?`,
          a: `High school students from grades IX to XII, both Romanian and international, are eligible to participate.`
        },
        {
          q: `How do I submit my artwork?`,
          a: `Artwork submissions include scaled simulations and portfolios submitted online by July 15, 2024.`
        },
        {
          q: `What are the judging criteria for the competition?`,
          a: `Judging criteria include artistic technique, creativity, and adherence to the theme.`
        }
      ]
    },
    contactSection: {
      description: `Contact us to enter our mural contest or for any other questions and suggestions!`,
      thankyouMessage: `Thank you for your message! We will get back to you soon.`,
      nameLabel: `name`,
      emailLabel: `email`,
      messageLabel: `message`,
    },
  },
  ro: {
    navigationHeader: {
      pages: [
        { id: 'about-us', title: 'Despre noi' },
        { id: 'schedule', title: 'Program' },
        { id: 'gallery', title: 'Galerie' },
        // { id: 'sponsors', title: 'Sponsors' },
        { id: 'faq', title: 'F.A.Q.' },
        { id: 'rules', title: 'Regulament' },
        { id: 'contact', title: 'Contact' },
      ]
    },
    navigationFooter: {
      pages: [
        { name: 'Despre noi', id: 'about-us', line: true },
        { name: 'Program', id: 'schedule', line: true },
        { name: 'Galerie', id: 'gallery', line: true },
        { name: 'F.A.Q.', id: 'faq', line: true },
        { name: 'Regulament.', id: 'rules', line: true },
        // { name: 'sponsors', id: 'sponsors' },
        { name: 'Contact', id: 'contact' },
      ]
    },
    mainTitle: `Bine ai venit la MURALS OF YOUTH`,
    subTitle: `transformă-ți imaginația în artă`,
    welcomeSection: {
      description: `Bine ai venit la <b>Murals of Youth</b>, un festival internațional care celebrează creativitatea elevilor de liceu în arta murală. Totul a început în 2023 în vibrantul oraș Cluj-Napoca, România. Festivalul oferă participanților oportunitatea de a-și expune abilitățile pe panouri mari, folosind diverse tehnici de pictură. Pentru a participa, elevii de liceu pot trimite formularul de înscriere online până pe 15 iulie. Un juriu format din patru membri, inclusiv artiști și profesori, va selecta participanții și câștigătorii, oferind și premii.`,
      unleashCreativity: `Arată-ți creativitatea la Festivalul “Murals of Youth” în <br> Cluj-Napoca, <br>
        <b>30 August – 1 September 2024!</b> <br>
        <span>Înscrierile sunt deschise până pe 15 iulie.</span>`,
      rulesOfParticipationTitle: "Regulament",
      rulesOfParticipation: "Participare la festival",
      breakdanceRules: "Regulament concurs breakdance",
      muralRules: "Regulament concurs arta murala",
    },
    artworksSection: {
      title: `M.Y. 2023`,
      hashtag: `#2023contestartwork`,
    },
    aboutSection: {
      introduction:  `<b>MURALS OF YOUTH</b>
        este o competiție internațională care celebrează creativitatea și talentul elevilor de liceu în domeniul artei murale.`,
      details: `Murals of Youth își propune să promoveze educația prin artă, oferind elevilor de liceu și publicului larg o platformă pentru a explora arta și abilitățile artistice.
        <br><br>
        Competiția de artă murală, un moment de vârf al festivalului, nu doar că încurajează o competiție sănătoasă, dar oferă și participanților oportunitatea de a învăța prin practică și de a-și expune talentele în fața unui public numeros.
        <br><br>
        Pe lângă competiție, M.Y. organizează ateliere de artă speciale pentru vizitatorii festivalului. Festivalul încurajează, de asemenea, un mediu propice pentru discuții deschise între elevi și profesioniști, permițându-le să împărtășească experiențe și să primească sfaturi practice.`,
    },
    scheduleSection: {
      description: `Programul complet va fi anunțat pe 25 august! Revino pentru a descoperi toate activitățile și evenimentele planificate.
        Te rugăm să reții că programul poate suferi modificări, așa că îți recomandăm să verifici actualizările regulat pentru a rămâne la curent cu cele mai recente noutăți.`,
      days: [
        {
          date: 'Vineri / 30.08',
          events: [
            {
              time: '10:00 - 20:00',
              title: 'Concurs artă murală',
              text: ``,
            },
            {
              time: '10:00 - 22:00',
              title: 'Desen asfalt',
            },
            {
              time: '10:00 - 22:00',
              title: 'Desen autobuze',
            },
            {
              time: '14:00 - 15:00',
              title: 'Conferință deschidere',
            },
            {
              time: '17:00 - 20:00',
              title: 'Ateliere',
            }
          ]
        },
        {
          date: 'Sâmbătă / 31.08',
          events: [
            {
              time: '10:00 - 20:00',
              title: 'Concurs artă murală',
              text: ``,
            },
            {
              time: '10:00 - 22:00',
              title: 'Desen autobuze',
            },
            {
              time: '10:00 - 22:00',
              title: 'Desen asfalt',
            },
            {
              time: '13:00 - 15:00',
              title: 'Conferință FOTOGRAFIA',
            },
            {
              time: '15:00 - 17:00',
              title: 'Ateliere',
            },
            {
              time: '17:00 - 20:00',
              title: 'Concurs breakdance',
            }
          ]
        },
        {
          date: 'Duminică / 01.09',
          events: [
            {
              time: '10:00 - 17:00',
              title: 'Concurs artă murală',
              text: ``,
            },
            {
              time: '10:00 - 17:00',
              title: 'Desen asfalt',
            },
            {
              time: '14:00 - 17:00',
              title: 'Ateliere',
            },
            {
              time: '17:00 - 18:00',
              title: 'Jurizare',
            },
            {
              time: '19:00',
              title: 'Premierea concurenților',
            },
            {
              time: '20:00',
              title: 'Concert Ada Morar',
            },
          ]
        }
      ]
    },
    gallerySection: {
      description: `Comunitatea 156, inițiată de Prof. Dr. Sergiu Georgian Mazerschi în 2021, este un grup dedicat proiectelor extracurriculare care evidențiază și dezvoltă creativitatea și talentul elevilor de la Liceul de Arte Vizuale „Romulus Ladea” din Cluj-Napoca. Pe site-ul lor, poți descoperi mai multe lucrări murale, inclusiv cele din cadrul Murals of Youth.
        <br><br>
        Pentru mai multe detalii și pentru a explora lucrările lor, vizitează pagina Comunitatea 156.`,
      viewMurals: `view C156 murals`,
    },
    sponsorsSection: {},
    faqSection: {
      faq: [
        {
          q: `Ce este “Murals of Youth”?`,
          a: `Murals of Youth este un festival internațional care celebrează creativitatea și arta stradală. Acesta constă în realizarea de lucrări murale în centrul orașului Cluj-Napoca și este dedicat elevilor de liceu.`
        },
        {
          q: `Cum mă pot înscrie?`,
          a: `Înscrierea implică trimiterea schițelor, portofoliilor și detaliilor de înregistrare online în perioada 18 martie – 15 iulie 2024.`
        },
        {
          q: `Unde se localizează festivalul?`,
          a: `Festivalul se desfășoară în Piața Unirii (centrul Clujului), Cluj-Napoca, România.`
        },
        {
          q: `Ce ar trebui să aduc?`,
          a: `Participanții trebuie să aducă materialele artistice și obiectele personale. Instrucțiuni specifice vor fi furnizate la înscriere, dar în mare parte vom oferi toate resursele necesare.`
        },
        {
          q: `Care sunt criteriile de eligibilitate pentru elevii de liceu?`,
          a: `Elevii de liceu din clasele a IX-a până la a XII-a, atât români, cât și internaționali, sunt eligibili pentru a participa.`
        },
        {
          q: `Cum îmi trimit lucrările artistice?`,
          a: `Trimiterea lucrărilor artistice include simulări scalate și portofolii, care trebuie să fie trimise online până pe 15 iulie 2024.`
        },
        {
          q: `Care sunt criteriile de evaluare pentru competiție?`,
          a: `Criteriile de evaluare includ tehnica artistică, creativitatea și respectarea temei.`
        }
      ]
    },
    contactSection: {
      description: `Pentru a participa la concursul nostru de artă murală sau pentru orice alte întrebări și sugestii, nu ezita să ne contactezi!`,
      thankyouMessage: `Vă mulțumim pentru mesaj! Vom reveni cu un răspuns în curând.`,
      nameLabel: `Nume`,
      emailLabel: `Email`,
      messageLabel: `Mesaj`,
    },
  },
}